  
  function getColorButtons() {
    return [
      { class: 'pms072', color: '#10069f' },
      { class: 'pms285', color: '#0072ce' },
      { class: 'pms306', color: '#00b5e2' },
      { class: 'pms341', color: '#007a53' },
      { class: 'pms354', color: '#00b140' },
      { class: 'pms375', color: '#8cba3b' },
      { class: 'pms802', color: '#5DDF48' },
      { class: 'pms185', color: '#e4002b' },
      { class: 'pms021', color: '#fe5000' },
      { class: 'pms804', color: '#FFA05D' },
      { class: 'pms806', color: '#FA7AC8' },
      { class: 'pms210', color: '#f99fc9' },
      { class: 'pmsyellow', color: '#fedd00' },
      { class: 'pms110', color: '#daaa00' },
      { class: 'pms420', color: '#c7c9c7' },
      { class: 'pms1545', color: '#653819' },
      { class: 'pms2602', color: '#87189d' },
      { class: 'pms2583', color: '#a05eb5' },
      { class: 'pms2562', color: '#d7a9e3' },
      { class: 'boxwhite', color: '#FFFFFF' },
      { class: 'boxblack', color: 'black' }
    ];
  }
  
  export function createColorButtons(target, action) {
    getColorButtons().forEach(button => {
      const btn = document.createElement('button');
      btn.className = 'bl_editorColorButton';
      btn.setAttribute('data-action', action);

      const colorDiv = document.createElement('div');
      colorDiv.className = `el_colorButton ${button.class}`;
      colorDiv.setAttribute('data-color', button.color);

      btn.appendChild(colorDiv);
      target.appendChild(btn);
    });
  }