import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["pointInput", "pointWrap", "recalFee", "addPoint", "pointNotice"];
  static values = { pointBalance: Number, totalPrice: Number }

  connect() {
    this.availablePoint = this.calculateAvailablePoint();
    this.pointInputTarget.addEventListener("mouseup", this.updatePoints.bind(this));
    this.pointInputTarget.addEventListener("keyup", this.updatePoints.bind(this));
    this.pointInputTarget.addEventListener("mouseup", this.validatePointInput.bind(this));
    this.pointInputTarget.addEventListener("keyup", this.validatePointInput.bind(this));
  }

  calculateAvailablePoint() {
    if (this.pointBalanceValue >= this.totalPriceValue / 2) {
      this.pointNoticeTarget.textContent = "※ 半額の割引までご利用いただけます";
      return this.totalPriceValue / 2;
    } else {
      return this.pointBalanceValue;
    }
  }

  updatePoints() {
    this.pointWrapTarget.classList.remove('hidden')
    let usePoint = parseInt(this.pointInputTarget.value) || 0;

    if (usePoint > this.availablePoint) {
      usePoint = this.availablePoint;
      this.pointInputTarget.value = usePoint;
    }

    const recalFee = this.totalPriceValue - usePoint;
    const addPoint = Math.floor(recalFee * 0.03);

    this.recalFeeTarget.textContent = recalFee.toLocaleString();
    this.addPointTarget.textContent = addPoint.toLocaleString();
  }

  validatePointInput() {
    let usePoint = parseInt(this.pointInputTarget.value);
    const maxPoint = parseInt(this.pointInputTarget.max);
    const minPoint = parseInt(this.pointInputTarget.min);

    if (usePoint > maxPoint) {
      this.pointInputTarget.value = maxPoint;
    }
    if (usePoint < minPoint) {
      this.pointInputTarget.value = minPoint;
    }
    if (isNaN(usePoint)) {
      this.pointInputTarget.value = '';
    }
  }
}
