import { Rect, Line } from 'fabric';

const width = 960;
const height = 300;

function calculateMockupSize(ratio) {
  const aspectRatio = 202 / ratio;
  const widthCanvas = Math.min(width, height * aspectRatio);
  return {
    mockupWidth: widthCanvas - 1,
    mockupHeight: widthCanvas / aspectRatio,
    mockupLeft: (width - widthCanvas) / 2,
    mockupTop: (height - (widthCanvas / aspectRatio)) / 2
  };
}

function createMockupObject(ratio, color) {
  const { mockupWidth, mockupHeight, mockupLeft, mockupTop } = calculateMockupSize(ratio);

  return new Rect({
    width: mockupWidth,
    height: mockupHeight,
    fill: color,
    stroke: color === "#FFFFFF" ? '#666666' : false,
    strokeWidth: color === "#FFFFFF" ? 1 : 0,
    left: mockupLeft,
    top: mockupTop,
    selectable: false,
    hasControls: false,
    evented: false,
    id: 'mockup'
  });
}

function createGuide(id, x1, y1, x2, y2) {
  return new Line([x1, y1, x2, y2], {
    stroke: 'lightgray',
    strokeWidth: 1,
    strokeDashArray: [5, 5],
    selectable: false,
    evented: false,
    id: id
  });
}

function createMockup(mockupCanvas, ratio, color) {
  const mockupObject = createMockupObject(ratio, color);
  const leftGuideline = createGuide('left', 24, 0, 24, 300);
  const rightGuideline = createGuide('right', 936, 0, 936, 300);
  const upGuideline = createGuide('up', 0, mockupObject.top + 7, width, mockupObject.top + 7);
  const downGuideline = createGuide('down', 0, mockupObject.top + mockupObject.height - 7, width, mockupObject.top + mockupObject.height - 7);
  const centerGuideline = createGuide('center', 480, 0, 480, 300);

  mockupCanvas.clear();
  mockupCanvas.add(leftGuideline);
  mockupCanvas.add(rightGuideline);
  mockupCanvas.add(upGuideline);
  mockupCanvas.add(downGuideline);
  mockupCanvas.add(centerGuideline);
  mockupCanvas.add(mockupObject);
  mockupCanvas.renderAll();
  mockupCanvas.sendObjectToBack(mockupObject);
  mockupCanvas.sendObjectToBack(centerGuideline);
  mockupCanvas.renderAll();

}
function updateMockupRatio(mockupCanvas, ratioButtons, ratio) {
  const mockupObject = mockupCanvas.getObjects().find(obj => obj.id === 'mockup');
  const upGuideline = mockupCanvas.getObjects().find(obj => obj.id === 'up');
  const downGuideline = mockupCanvas.getObjects().find(obj => obj.id === 'down');
  const { mockupWidth, mockupHeight, mockupLeft, mockupTop } = calculateMockupSize(ratio);

  mockupObject.set({
    width: mockupWidth,
    height: mockupHeight,
    left: mockupLeft,
    top: mockupTop
  })
  upGuideline.set({
    y1: mockupObject.top + 7,
    y2: mockupObject.top + 7
  });
  downGuideline.set({
    y1: mockupObject.top + mockupObject.height - 7,
    y2: mockupObject.top + mockupObject.height - 7
  });

  mockupCanvas.renderAll();

  updateButton(ratioButtons, ratio)
}

function updateButton(containerElement, ratio) {
  const buttons = containerElement.querySelectorAll('button');
  buttons.forEach(button => button.classList.remove('active'));

  const activeButton = Array.from(buttons).find(button => button.textContent === `${ratio}mm`);
  if (activeButton) {
    activeButton.classList.add('active');
  }
}

function updateMockupColor(mockup, color) {
  const mockupObject = mockup.getObjects().find(obj => obj.id === 'mockup');
  mockupObject.set({
    fill: color,
    stroke: color === "#FFFFFF" ? '#666666' : false,
    strokeWidth: color === "#FFFFFF" ? 1 : 0
  });

  mockup.renderAll();
}

export { createMockup, updateMockupRatio, updateMockupColor };
