import FontFaceObserver from "fontfaceobserver";

export const fonts = [
  { value: "Noto Sans JP:wght@400;700", displayName: "[JP] Noto Sans JP" },
  { value: "M PLUS Rounded 1c:wght@400;700", displayName: "[JP] M+PLUS Rounded 1c" },
  { value: "Zen Maru Gothic:wght@400;700", displayName: "[JP] ZEN丸ゴシック" },
  { value: "Shippori Mincho:wght@400;700", displayName: "[JP] しっぽり明朝" },
  { value: "Mochiy Pop P One:wght@400;700", displayName: "[JP] モッチーポップ" },
  { value: "Open Sans:wght@400;700", displayName: "Open Sans" },
  { value: "Oswald:wght@400;700", displayName: "Oswald" },
  { value: "Montserrat:wght@400;700", displayName: "Montserrat" },
  { value: "Raleway:wght@400;700", displayName: "Raleway" },
  { value: "Poppins:wght@400;700", displayName: "Poppins" },
  { value: "Halant:wght@400;700", displayName: "Halant" },
  { value: "Roboto Slab:wght@400;700", displayName: "Roboto Slab" },
];

export function preloadFonts() {
  const fontPromises = fonts.map((font) => {
    const fontName = font.value.split(":")[0];
    const fontUrl = `https://fonts.googleapis.com/css2?family=${font.value.replace(/ /g, "+")}&display=swap`;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = fontUrl;
    document.head.appendChild(link);

    const fontObserver = new FontFaceObserver(fontName, { timeout: 5000 });
    return fontObserver.load().catch((error) => {
      console.warn(`フォント "${fontName}" の読み込みに失敗:`, error);
    });
  });

  return Promise.all(fontPromises);
}

export function populateFontFamilyDropdown(selectElement) {
  fonts.forEach((font) => {
    const option = document.createElement("option");
    option.value = font.value.split(":")[0];
    option.innerText = font.displayName;
    selectElement.appendChild(option);
  });
}
