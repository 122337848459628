import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["postcode", "prefectureCode", "addressCity"];

  connect() {
    $(this.postcodeTarget).on("input", () => {
      const cleanPostcode = this.postcodeTarget.value.replace(/-/g, '');
      this.postcodeTarget.value = cleanPostcode;
      if (cleanPostcode.length === 7) {
        this.initJpostal();
      }
    });
  }

  initJpostal() {
    $(this.postcodeTarget).jpostal({
      postcode: [this.postcodeTarget],
      address: {
        [`#${this.prefectureCodeTarget.id}`]: "%3",
        [`#${this.addressCityTarget.id}`]: "%4%5%6%7"
      }
    });
  }
}