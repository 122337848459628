import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  open(event) {
    const imageUrl = event.currentTarget.getAttribute("data-image");

    this.modalTarget.innerHTML = `
      <div class="modal-overlay" data-action="click->image-modal#close">
        <div class="modal-content" data-action="click->image-modal#stop">
          <button class="modal-close" data-action="click->image-modal#close">&times;</button>
          <img src="${imageUrl}" class="modal-image">
        </div>
      </div>
    `;
  }

  close() {
    this.modalTarget.innerHTML = "";
  }

  stop(event) {
    event.stopPropagation();
  }
}