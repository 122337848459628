export function updateEditor(selectedObject) {

  const textEditorTarget = this.textEditorTarget;
  const imageEditorTarget = this.imageEditorTarget;
  const objectEditorTarget = this.objectEditorTarget;
  const colorEditorTarget = this.colorEditorTarget;
  const fontSelectTarget = this.fontSelectTarget;
  const changeTextBoldTarget = this.changeTextBoldTarget;

  if (!selectedObject) {
    textEditorTarget.classList.add("hidden");
    imageEditorTarget.classList.add("hidden");
    objectEditorTarget.classList.add("hidden");
    colorEditorTarget.classList.add("hidden");
  } else if (selectedObject.type === "i-text") {
    textEditorTarget.classList.remove("hidden");
    imageEditorTarget.classList.add("hidden");
    objectEditorTarget.classList.add("hidden");
    colorEditorTarget.classList.remove("hidden");
  } else if (selectedObject.type === "image") {
    imageEditorTarget.classList.remove("hidden");
    textEditorTarget.classList.add("hidden");
    objectEditorTarget.classList.add("hidden");
    colorEditorTarget.classList.add("hidden");
  } else if (["rect", "polygon", "triangle", "path", "circle"].includes(selectedObject.type)) {
    imageEditorTarget.classList.add("hidden");
    textEditorTarget.classList.add("hidden");
    objectEditorTarget.classList.remove("hidden");
    colorEditorTarget.classList.remove("hidden");
  }

  if (selectedObject && selectedObject.type === 'i-text') {
    fontSelectTarget.value = selectedObject.fontFamily;
    changeTextBoldTarget.checked = selectedObject.fontWeight === '700';
  }
}

export function updateEditorObject(textEditor, imageEditor, objectEditor) {
  imageEditor.classList.add("hidden");
  textEditor.classList.add("hidden");
  objectEditor.classList.remove("hidden");
}