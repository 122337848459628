import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["checkbox", "button"];

  connect() {
    this.updateButtonState();
  }

  toggle() {
    this.updateButtonState();
  }

  updateButtonState() {
    const checkbox = this.checkboxTarget;
    const agreeButton = this.buttonTarget;

    if (checkbox.checked) {
      agreeButton.disabled = false;
      agreeButton.classList.remove("js_agree__disabled");
    } else {
      agreeButton.disabled = true;
      agreeButton.classList.add("js_agree__disabled");
    }
  }
}