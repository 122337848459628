import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="design-selector"
export default class extends Controller {
  static targets = ["designItem", "selectedDesignId"]

  connect() {
    this.selectedDesignItem = null
  }

  select(event) {
    event.preventDefault()
    
    const designItem = event.currentTarget.closest('.bl_designSave_item')

    if (designItem === this.selectedDesignItem) {
      this.deselect(designItem)
    } else {
      this.clearSelection()
      this.selectItem(designItem)
    }
  }

  clearSelection() {
    this.designItemTargets.forEach(item => item.classList.remove('is-selected'))
    document.querySelectorAll('.check-icon').forEach(icon => icon.remove())
    this.selectedDesignIdTarget.value = ''
    this.selectedDesignItem = null
  }

  selectItem(item) {
    item.classList.add('is-selected')
    const icon = document.createElement('div')
    icon.classList.add('check-icon')
    item.appendChild(icon)

    this.selectedDesignIdTarget.value = item.dataset.designId
    this.selectedDesignItem = item
  }

  deselect(item) {
    item.classList.remove('is-selected')
    const icon = item.querySelector('.check-icon')
    if (icon) icon.remove()
    this.selectedDesignIdTarget.value = ''
    this.selectedDesignItem = null
  }
}
