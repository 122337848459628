import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  load(event) {
    event.preventDefault();

    const url = event.currentTarget.getAttribute("data-url");

    fetch(url)
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const termsBody = doc.querySelector("#modal_content");

        if (termsBody) {
          this.modalTarget.innerHTML = `
            <div class="modal-overlay" data-action="click->modal#close">
              <div class="modal-content" data-action="click->modal#stop">
                <button class="modal-close" data-action="click->modal#close">&times;</button>
                ${termsBody.innerHTML}
              </div>
            </div>
          `;
        } else {
          this.modalTarget.innerHTML = "<p>データの取得に失敗しました。</p>";
        }
      });
  }

  close() {
    this.modalTarget.innerHTML = "";
  }

  stop(event) {
    event.stopPropagation();
  }
}
