import { Rect, Triangle, Path, Polygon, Circle } from 'fabric';

export function addSquare(drawing) {
  const rect = new Rect({
    width: 50,
    height: 50,
    fill: '#fff',
    left: drawing.width / 2,
    top: drawing.height / 2,
    originX: 'center',
    originY: 'center',
  });
  drawing.add(rect);
  drawing.setActiveObject(rect);
}

export function addTriangle(drawing) {
  const rect = new Triangle({
    width: 50,
    height: 50,
    fill: '#fff',
    left: drawing.width / 2,
    top: drawing.height / 2,
    originX: 'center',
    originY: 'center',
  });
  drawing.add(rect);
  drawing.setActiveObject(rect);
}

export function addHeart(drawing) {
  const heart = new Path(`
    M 272.70141,238.71731 \
    C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
    C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
    C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
    C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
    C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
    C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
    z 
  `, {
    fill: '#e4002b',
    left: drawing.width / 2,
    top: drawing.height / 2,
    scaleX: 0.2,
    scaleY: 0.2,
    originX: 'center',
    originY: 'center',
  });
  drawing.add(heart);
  drawing.setActiveObject(heart);
}

export function addStar(drawing) {
  const starPoints = [
    { x: 174.95, y: 37.5 },
    { x: 189.5, y: 80.45 },
    { x: 234.5, y: 80.45 },
    { x: 198.5, y: 107.45 },
    { x: 211.5, y: 150.45 },
    { x: 175, y: 124.95 },
    { x: 138.45, y: 150.5 },
    { x: 151.5, y: 107.5 },
    { x: 115.5, y: 80.5 },
    { x: 160.5, y: 80.5 }
  ];
  const star = new Polygon(starPoints, {
    fill: '#fff',
    left: drawing.width / 2,
    top: drawing.height / 2,
    originX: 'center',
    originY: 'center',
  });

  drawing.add(star);
  drawing.setActiveObject(star);
}

export function addRoundedRect(drawing) {
  const initialCornerRadius = 10;

  const roundedRect = new Rect({
    width: 200,
    height: 50,
    fill: '#fff',
    left: drawing.width / 2,
    top: drawing.height / 2,
    originX: 'center',
    originY: 'center',
    rx: initialCornerRadius,
    ry: initialCornerRadius,
    selectable: true,
    scalable: true,
  });

  roundedRect.on('scaling', function () {
    const newWidth = this.width * this.scaleX;
    const newHeight = this.height * this.scaleY;

    this.set({
      width: newWidth,
      height: newHeight,
      scaleX: 1,
      scaleY: 1,
      rx: initialCornerRadius,
      ry: initialCornerRadius
    });
    this.setCoords();
  });

  drawing.add(roundedRect);
  drawing.setActiveObject(roundedRect);
}

export function addCircle(drawing) {
  const circle = new Circle({
    radius: 25,
    fill: '#fff',
    left: drawing.width / 2,
    top: drawing.height / 2,
    originX: 'center',
    originY: 'center',
  });
  drawing.add(circle);
  drawing.setActiveObject(circle);
}