import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["button"];

  dialogue(event) {
    // ここに確認ダイアログのロジックを実装
    const confirmed = window.confirm("操作を実行しますか？");
    if (!confirmed) {
      event.preventDefault();
    }
  }
}