import { FabricImage } from "fabric";
export const uploadedImages = [];

export function handleFileInput(file, drawing, notifyHistory) {
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (e) => {
    const imageUrl = e.target.result;

    if (!uploadedImages.includes(imageUrl)) {
      uploadedImages.push(imageUrl);
    }

    const fileInput = document.getElementById('upload-image');
    fileInput.value = '';

    FabricImage.fromURL(imageUrl)
      .then((img) => {
        img.set({
          left: 20,
          top: 20,
          originX: "left",
          originY: "left",
        });

        img.scaleToHeight(120);
        drawing.add(img);
        drawing.setActiveObject(img);

        drawing.renderAll();
        notifyHistory();
      })
      .catch((error) => {
        console.error("画像の読み込みに失敗しました:", error);
      });
  };
  reader.readAsDataURL(file);
}

export function addImage(fileInputTarget) {
  fileInputTarget.click();
}

export function changeImageInversion(drawing) {
  const activeObject = drawing.getActiveObject();
  if (!(activeObject && activeObject.type === 'image')) {
    return;
  }

  const img = new Image();
  img.src = activeObject.getSrc();

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] = 255 - data[i];       // Red
      data[i + 1] = 255 - data[i + 1]; // Green
      data[i + 2] = 255 - data[i + 2]; // Blue
    }

    ctx.putImageData(imageData, 0, 0);
    const invertedImageUrl = canvas.toDataURL();

    activeObject.setSrc(invertedImageUrl);
  };
}

export function changeImageTransparent(drawing) {
  const activeObject = drawing.getActiveObject();
  if (!activeObject || !(activeObject instanceof FabricImage)) {
    return;
  }

  const img = new Image();
  img.src = activeObject.getSrc();

  img.onload = () => {
    const canvasEl = document.createElement("canvas");
    const ctx = canvasEl.getContext("2d");

    canvasEl.width = img.width;
    canvasEl.height = img.height;
    ctx.drawImage(img, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      if (r > 200 && g > 200 && b > 200) {
        data[i + 3] = 0;
      }
    }

    ctx.putImageData(imageData, 0, 0);
    const transparentImageUrl = canvasEl.toDataURL();

    activeObject.setSrc(transparentImageUrl);
  };
}