export function fetchData(jsonPath) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.responseText);
        } else {
          reject(new Error(`Failed to fetch data (status: ${xhr.status})`)); // エラー処理
        }
      }
    };
    xhr.open('GET', jsonPath, true);
    xhr.send();
  });
}