import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltipText"];

  showTooltip(event) {
    const tooltipText = event.target.getAttribute('data-tooltip');
    this.tooltipTextTarget.innerHTML = tooltipText;
    this.tooltipTextTarget.style.visibility = 'visible';
  }

  hideTooltip() {
    this.tooltipTextTarget.innerHTML = '';
    this.tooltipTextTarget.style.visibility = 'hidden';
  }
}