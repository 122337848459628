import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="history"
export default class extends Controller {
  connect() {
    this.undoHistory = [];
    this.redoHistory = [];
    this.lockHistory = false;

    this.element.addEventListener("canvas:modified", this.saveState.bind(this));
  }

  saveState(event) {
    if (!event.detail.canvas) return;
    this.canvas = event.detail.canvas;
    const state = JSON.stringify(this.canvas.toJSON());
    this.undoHistory.push(state);
    this.redoHistory = [];
  }

  undo() {
    if (this.undoHistory.length === 0) return;
  
    this.redoHistory.push(this.undoHistory.pop());
    if (this.undoHistory.length === 0) {

      this.canvas.clear();
    } else {
      this.canvas.loadFromJSON(this.undoHistory[this.undoHistory.length - 1]);
      setTimeout(() => {
        this.canvas.renderAll(); 
      }, 50);
    }
  }

  redo() {
    if (this.redoHistory.length === 0) return;
    this.undoHistory.push(this.redoHistory.pop());
    this.canvas.loadFromJSON(this.undoHistory[this.undoHistory.length - 1]);
    setTimeout(() => {
      this.canvas.renderAll();
    }, 50);
  }
}