import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="uploads"
export default class extends Controller {
  static targets = ["dropZone", "filePreview", "fileInput", "fileLabelWrap", "fileLabel", "fileContainer"]

  connect() {
    this.filePreviews = []
    this.fileContainerWidth = this.fileContainerTarget.offsetWidth

    this.fileLabelTarget.addEventListener("dragover", this.handleDragOver.bind(this))
    this.fileLabelTarget.addEventListener("dragleave", this.handleDragLeave.bind(this))
    this.fileLabelTarget.addEventListener("drop", this.handleDrop.bind(this))

    this.fileInputTargets.forEach(input => {
      input.addEventListener("change", this.handleFileSelect.bind(this))
    })
  }

  handleFileSelect(event) {
    const files = event.target.files
    if (files.length > 0) {
      event.target.setAttribute("data-attached", "true");
      const nextInput = this.getNextAvailableInput()
      if (nextInput) {
        this.updateLabel(nextInput)
      }
      this.setPreview(files[0])
    }
  }

  getNextAvailableInput() {
    return this.fileInputTargets.find(input => {
      return !input.hasAttribute("data-attached") && input.files && input.files.length === 0;
    });
  }

  updateLabel(nextInput) {
    const newId = nextInput.id
    this.fileLabelTarget.setAttribute("for", newId)
  }

  handleDragOver(event) {
    event.preventDefault()
    this.fileLabelTarget.style.background = '#C5E5FF'
  }

  handleDragLeave(event) {
    event.preventDefault()
    this.fileLabelTarget.style.background = '#f5f5f5'
  }

  handleDrop(event) {
    event.preventDefault()
    this.fileLabelTarget.style.background = '#f5f5f5'

    const files = event.dataTransfer.files
    if (files.length !== 1) {
      alert('一度にアップロードできるファイルは1つだけです')
      return
    }

    this.setPreview(files[0])
  }

  setPreview(file) {
    const id = this.getNextAvailableId()
    if (id === -1) return

    this.filePreviews.push({ id: id, file: file })

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const image = reader.result
      const imageFlag = file.type.startsWith('image')
      const fileNameHead = file.name.slice(0, 6)
      const fileNameFoot = file.name.slice(-6)

      const html = this.buildHTML(id, imageFlag, image, fileNameHead, fileNameFoot)
      this.filePreviewTarget.insertAdjacentHTML('beforeend', html)
      this.setLabel()
    }
  }

  getNextAvailableId() {
    for (let id = 0; id < 4; id++) {
      if (!this.filePreviews.some(item => item.id === id)) {
        return id
      }
    }
    return -1
  }

  setLabel() {
    const id = this.getNextAvailableId()
    if (id === -1) {
      this.fileLabelWrapTarget.classList.add('hidden')
    } else {
      this.fileLabelWrapTarget.classList.remove('hidden')

      
      const filePreviewtWidth = this.filePreviewTarget.offsetWidth
      const labelWidth = this.fileContainerWidth - filePreviewtWidth
      this.fileLabelWrapTarget.style.width = `${labelWidth}px`
    }
  }

  buildHTML(id, imageFlag, image, fileNameHead, fileNameFoot) {
    const imageSrc = imageFlag ? image : '/file.png'
    const imageCaption = imageFlag ? '' : `<p class="el_prevBox_imageName">${fileNameHead}...${fileNameFoot}</p>`

    return `
      <div class="el_prevBox" id="js_prevBox__${id}">
        <div class="el_prevBox_image">
          <img src="${imageSrc}" alt="preview">
        </div>
        <div class="el_prevBox_del">
          <div class="el_prevBox_delBtn" data-action="click->uploads#removeUploadFile" data-id="${id}">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="el_prevBox_delIcon">
                      <path d="M296 64h-80a7.909 7.909 0 0 0-8 7.82V96h96V72a7.909 7.909 0 0 0-7.82-8H296z" style="fill:none"/>
                      <path d="M432 96h-96V72c0-22.09-17.91-40-40-40h-80c-22.09 0-40 17.91-40 40v24H80c-8.84 0-16 7.16-16 16s7.16 16 16 16h17l19 304.92c1.42 26.85 22 47.08 48 47.08h184c26.13 0 46.3-19.78 48-47l19-305h17c8.84 0 16-7.16 16-16s-7.16-16-16-16zM192.57 416H192c-8.62.01-15.69-6.82-16-15.43l-8-224c-.31-8.84 6.59-16.26 15.43-16.57 8.84-.31 16.26 6.59 16.57 15.43l8 224c.32 8.83-6.58 16.25-15.41 16.57h-.02zM272 400c0 8.84-7.16 16-16 16s-16-7.16-16-16V176c0-8.84 7.16-16 16-16s16 7.16 16 16v224zm32-304h-96V72a7.909 7.909 0 0 1 7.82-8H296a7.909 7.909 0 0 1 8 7.82V96zm32 304.57c-.31 8.61-7.38 15.44-16 15.43h-.58c-8.83-.31-15.73-7.73-15.42-16.56v-.01l8-224c.31-8.84 7.73-15.74 16.57-15.43 8.84.31 15.74 7.73 15.43 16.57l-8 224z" style="fill:#fff"/>
                  </svg>
          </div>
        </div>
        ${imageCaption}
      </div>
    `
  }

  removeUploadFile(event) {
    const id = event.currentTarget.dataset.id;
    const previewElement = document.getElementById(`js_prevBox__${id}`);
    
    // プレビューを削除
    previewElement.remove();
    
    // 対応するファイル入力をリセット
    const inputElement = this.fileInputTargets[id];

    if (inputElement) {
      inputElement.value = '';  // ファイル入力をリセット
      inputElement.removeAttribute('data-attached');  // data-attachedを削除
    }
  
    // プレビューの配列から削除
    this.filePreviews = this.filePreviews.filter(item => item.id !== Number(id));
  
    // ラベルの更新
    this.setLabel();
  }
}
