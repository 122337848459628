import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
    // ページ読み込み時に既存のフラッシュメッセージを自動で消す
    setTimeout(() => this.hide(), 3000) // 3秒後に消える
  }

  show(event) {
    const [data, _status, _xhr] = event.detail
    this.messageTarget.innerText = data.message
    this.messageTarget.classList.add("show")

    setTimeout(() => this.hide(), 3000) // 3秒後に消える
  }

  hide() {
    this.messageTarget.classList.remove("show")
    this.messageTarget.innerText = ""
  }
}
