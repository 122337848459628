import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainImage", "subImage"];

  changeImage(event) {
    const newSrc = event.target.src;

    if (newSrc !== this.mainImageTarget.src) {
      const img = new Image();
      img.onload = () => {
        this.mainImageTarget.src = newSrc;
        this.subImageTargets.forEach(subImage => {
          subImage.classList.remove('active');
        });
        event.target.parentElement.classList.add('active');
      };
      img.src = newSrc;
    }
  }
}
