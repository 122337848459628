import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "menu", "title"];

  connect() {
    this.scrollPosition = 0;
    this.isMenuOpen = false;
    document.addEventListener("click", this.closeMenuOnClickOutside.bind(this));
    this.titleTargets.forEach(title => {
      title.addEventListener('click', this.accordion.bind(this));
    });
  }

  disconnect() {
    document.removeEventListener("click", this.closeMenuOnClickOutside.bind(this));
  }

  toggle(event) {
    if (this.isMenuOpen) {
      this.close(event);
    } else {
      this.open(event);
    }
  }

  open(event) {
    this.scrollPosition = window.scrollY;

    document.body.classList.add('_fixed');
    document.body.style.top = `-${this.scrollPosition}px`;

    this.wrapperTarget.style.display = 'block';

    $(this.menuTarget).animate({
      'marginLeft': '400px'
    }, 300);

    this.isMenuOpen = true;
  }

  close(event) {
    event.stopPropagation();
    document.body.classList.remove('_fixed');
    document.body.style.top = '0';

    window.scrollTo(0, this.scrollPosition);

    $(this.menuTarget).animate({
      'marginLeft': '-400px'
    }, 300);

    setTimeout(() => {
      this.wrapperTarget.style.display = 'none';
    }, 300);

    this.isMenuOpen = false;
  }

  closeMenuOnClickOutside(event) {
    if (this.isMenuOpen && !this.menuTarget.contains(event.target) && this.wrapperTarget.contains(event.target)) {
      this.close(event);
    }
  }

  accordion(event) {
    const clickedTitle = event.currentTarget;
    const content = clickedTitle.nextElementSibling;

    $(this.titleTargets).not(clickedTitle).removeClass('open');
    $(this.titleTargets).not(clickedTitle).next().slideUp(300);

    $(clickedTitle).toggleClass('open');
    $(content).slideToggle(300);
  }

}