import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["title", "body"]

  toggle(event) {
    const index = this.titleTargets.indexOf(event.currentTarget);
    const body = this.bodyTargets[index];
    const isHidden = body.classList.contains("hidden");

    if (isHidden) {
      this.open(body, event.currentTarget);
    } else {
      this.close(body, event.currentTarget);
    }
  }

  open(body, title) {
    body.classList.remove("hidden");
    body.style.maxHeight = body.scrollHeight + "px"; 
    title.classList.add("open");
  }

  close(body, title) {
    body.style.maxHeight = null;
    title.classList.remove("open");
    setTimeout(() => {
      body.classList.add("hidden");
    }, 300);
  }
}
