import Swiper from 'swiper';
import { Controller } from '@hotwired/stimulus';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, Autoplay]);

export default class extends Controller {
  static values = { topSlider: Boolean, guideSlider: Boolean }

  async connect() {
    await this.waitForImagesToLoad();

    if (this.topSliderValue) {
      this.initializeSlider("swiper", this.initializeSwiper.bind(this));
    }
    if (this.guideSliderValue) {
      this.initializeSlider("gallerySwiper", this.initializeGallerySwiper.bind(this));
    }
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy();
    }
    if (this.gallerySwiper) {
      this.gallerySwiper.destroy();
    }
  }

  initializeSlider(property, initializer) {
    this[property] = initializer();
    setTimeout(() => {
      this[property].update();
      this.element.style.height = "auto";
      this.element.style.opacity = "1";
    }, 100);
  }

  initializeSwiper() {
    return new Swiper(".js_topSlider", {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        clickable: true,
      },
      breakpoints: {
        0: { slidesPerView: 1.0 },
        503: { slidesPerView: 1.1 },
        552: { slidesPerView: 1.2 },
        600: { slidesPerView: 1.4, spaceBetween: 20 },
        705: { slidesPerView: 1.6, spaceBetween: 20 },
        807: { slidesPerView: 1.8, spaceBetween: 25 },
        912: { slidesPerView: 2.0, spaceBetween: 25 },
        1014: { slidesPerView: 2.2, spaceBetween: 30 },
        1124: { slidesPerView: 2.5, spaceBetween: 30 },
        1280: { slidesPerView: 2.8, spaceBetween: 30 },
        1439: { slidesPerView: 3.2, spaceBetween: 30 },
        1640: { slidesPerView: 3.6, spaceBetween: 30 },
        1847: { slidesPerView: 4.0, spaceBetween: 30 },
        2056: { slidesPerView: 4.5, spaceBetween: 30 },
        2200: { slidesPerView: 4.5, spaceBetween: 50 },
      },
    });
  }

  initializeGallerySwiper() {
    return new Swiper('.js_guideSlider', {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      speed: 4000,
      allowTouchMove: false,
      autoplay: {
        delay: 0,
        disableOnInteraction: false
      },
      spaceBetween: 20,
      breakpoints: {
        0: { slidesPerView: 2 },
        500: { slidesPerView: 5.5 },
      }
    });
  }

  waitForImagesToLoad() {
    return new Promise((resolve) => {
      const images = this.element.querySelectorAll("img");
      let loadedCount = 0;

      if (images.length === 0) {
        resolve();
      }

      images.forEach((img) => {
        if (img.complete) {
          loadedCount++;
          if (loadedCount === images.length) resolve();
        } else {
          img.addEventListener("load", () => {
            loadedCount++;
            if (loadedCount === images.length) resolve();
          });
          img.addEventListener("error", () => {
            loadedCount++;
            if (loadedCount === images.length) resolve();
          });
        }
      });
    });
  }

}