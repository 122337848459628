import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleElement"]

  connect() {
    if (this.hasToggleElementTarget && this.element.dataset.toggleCondition === "resize") {
      this.checkResize()
      window.addEventListener("resize", this.checkResize.bind(this))
    }
  }

  disconnect() {
    window.removeEventListener("resize", this.checkResize.bind(this))
  }

  checkResize() {
    if (window.innerWidth < 1000) {
      this.toggleElementTarget.classList.remove("hidden")
    } else {
      this.toggleElementTarget.classList.add("hidden")
    }
  }
}