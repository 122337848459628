import { Controller } from "@hotwired/stimulus";
import { Canvas } from 'fabric';
import { preloadFonts, populateFontFamilyDropdown } from "../controllers/design_canvas/utils/font_loader";
import { updateEditor, updateEditorObject } from "../controllers/design_canvas/utils/canvas_helpers";
import { createMockup, updateMockupRatio, updateMockupColor } from '../controllers/design_canvas/utils/mockup';
import { createColorButtons } from '../controllers/design_canvas/utils/color_palette';

import { addText, changeTextBold, changeTextColor, changeFont } from "../controllers/design_canvas/utils/text_operations";
import { handleFileInput, addImage, changeImageInversion, uploadedImages, changeImageTransparent } from "../controllers/design_canvas/utils/image_operations";
import { addSquare, addRoundedRect, addCircle, addTriangle, addStar, addHeart } from "../controllers/design_canvas/utils/object_operations";


export default class extends Controller {
  static targets = ["drawing", "mockup", "textEditor",
    "imageEditor", "fontSelect", "changeTextBold", "ratioButtons",
    "printColor", "bodyColor", "fileInput", "objectEditor", "colorEditor"];

  connect() {
    this.initializeCanvas();
    this.initializeBodyEditor();
    this.initializeColorPalette();
    preloadFonts().then(() => {
      populateFontFamilyDropdown(this.fontSelectTarget);
    });

    this.selectedObject = null;
    this.drawing.on("selection:created", () => this.handleSelection());
    this.drawing.on("selection:updated", () => this.handleSelection());
    this.drawing.on("selection:cleared", () => this.handleSelection());
    this.drawing.on("object:modified", () => this.notifyHistory());

    this.fontSelectTarget.addEventListener("change", this.changeFont.bind(this));
  }


  initializeCanvas() {
    const canvasWidth = 960;
    const canvasHeight = 300;

    this.drawing = new Canvas(this.drawingTarget);
    this.drawing.width = canvasWidth;
    this.drawing.height = canvasHeight;
    this.mockup = new Canvas(this.mockupTarget);
    this.mockup.width = canvasWidth;
    this.mockup.height = canvasHeight;

    // Mockup Load
    const canvasDataElement = document.getElementById('canvas-data');
    this.mockupSizeValue = parseInt(canvasDataElement.dataset.mockupSize, 10) || 12;
    this.mockupColorValue = canvasDataElement.dataset.mockupColor || "#10069f";

    createMockup(this.mockup, this.mockupSizeValue, this.mockupColorValue);

    // Canvas Load
    const canvasJson = JSON.parse(document.getElementById("canvas-json").textContent);
    this.drawing.loadFromJSON(canvasJson, () => {
      this.drawing.getObjects().forEach(obj => {
        if (obj.type === 'i-text') {
          const fontFamily = obj.get('fontFamily');
          obj.set('fontFamily', fontFamily);
          obj.set('dirty', true);
        }
      });
      setTimeout(() => {
        this.drawing.renderAll();
      }, 50);
    });

    
  }

  initializeBodyEditor() {
    const ratios = [9, 12, 15, 20, 25];
    const containerElement = this.ratioButtonsTarget;

    ratios.forEach(ratio => {
      const button = this.createButton(`${ratio}mm`, () => {
        updateMockupRatio(this.mockup, this.ratioButtonsTarget, ratio);
      });

      const isActive = (this.mockupSizeValue || 12) === ratio;
      if (isActive) {
        button.classList.add('active');
      }

      containerElement.appendChild(button);
    });
  }

  createButton(label, onClick) {
    const button = document.createElement('button');
    button.textContent = label;
    button.addEventListener('click', onClick);
    return button;
  }

  initializeColorPalette() {
    createColorButtons(this.printColorTarget, 'click->canvas#changeTextColor');
    createColorButtons(this.bodyColorTarget, 'click->canvas#changeMockupColor');
  }

  notifyHistory() {
    const event = new CustomEvent("canvas:modified", {
      bubbles: true,
      detail: { action: "object:modified", canvas: this.drawing }
    });
    this.element.dispatchEvent(event);
  }

  handleSelection() {
    this.selectedObject = this.drawing.getActiveObject();
    updateEditor.call(this, this.selectedObject);
  }

  updateEditorObject() {
    updateEditorObject(this.textEditorTarget, this.imageEditorTarget, this.objectEditorTarget);
  }

  changeMockupColor(event) {
    updateMockupColor(this.mockup, event.target.getAttribute('data-color'));
  }

  renderAndHistory() {
    setTimeout(() => {
      this.drawing.renderAll();
    }, 100);
    this.notifyHistory()
  }

  addText() {
    addText(this.drawing, this.fontSelectTarget);
    this.renderAndHistory();
  }

  changeFont() {
    changeFont(this.selectedObject, this.fontSelectTarget);
    this.renderAndHistory();
  }

  changeTextColor(event) {
    changeTextColor(this.drawing, event);
    this.renderAndHistory();
  }

  changeTextBold(event) {
    changeTextBold(this.drawing, event);
    this.renderAndHistory();
  }

  fileInputChange(event) {
    const file = event.target.files[0];
    if (!file) return;
    handleFileInput(file, this.drawing, this.notifyHistory.bind(this));
  }

  addImage() {
    addImage(this.fileInputTarget);
  }

  changeImageTransparent() {
    changeImageTransparent(this.drawing);
    this.renderAndHistory();
  }

  changeImageInversion() {
    changeImageInversion(this.drawing);
    this.renderAndHistory();
  }

  addSquare() {
    addSquare(this.drawing);
    this.renderAndHistory();
  }

  addRoundedRect() {
    addRoundedRect(this.drawing);
    this.renderAndHistory();
  }

  addCircle() {
    addCircle(this.drawing);
    this.renderAndHistory();
  }

  addTriangle() {
    addTriangle(this.drawing);
    this.renderAndHistory();
  }

  addStar() {
    addStar(this.drawing);
    this.renderAndHistory();
  }

  addHeart() {
    addHeart(this.drawing);
    this.renderAndHistory();
  }

  tobackObject() {
    const activeObject = this.drawing.getActiveObject();
    if (!activeObject) return;
    this.drawing.sendObjectToBack(activeObject);
    this.drawing.discardActiveObject();
    this.renderAndHistory();
  }

  tofrontObject() {
    const activeObject = this.drawing.getActiveObject();
    if (!activeObject) return;
    this.drawing.bringObjectToFront(activeObject);
    this.drawing.discardActiveObject();
    this.renderAndHistory();
  }

  deleteObject() {
    const activeObject = this.drawing.getActiveObject();
    if (!activeObject) return;
    this.drawing.remove(activeObject);
    this.drawing.discardActiveObject();
    this.renderAndHistory();
  }

  saveCanvas() {
    document.getElementById('loading-overlay').style.display = 'flex';

    this.generateThumbnail()
      .then(combinedData => {
        const thumbnailBlob = this.dataURLToBlob(combinedData);

        const formData = new FormData();
        formData.append('design_maker[thumbnail_img]', thumbnailBlob, `thumbnail_${Date.now()}.png`);

        const canvasJSON = JSON.stringify(this.drawing.toJSON());
        const canvasBlob = new Blob([canvasJSON], { type: 'application/json' });
        formData.append('design_maker[canvas]', canvasBlob, `canvas_${Date.now()}.json`);

        const activeButton = document.querySelector('#ratio-buttons .active');
        formData.append('design_maker[mockup_size]', activeButton.textContent);

        const mockupObject = this.mockup.getObjects().find(obj => obj.id === 'mockup');
        formData.append('design_maker[mockup_color]', mockupObject.fill);

        uploadedImages.forEach((file, index) => {
          const byteCharacters = atob(file.split(',')[1]);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset++) {
            byteArrays.push(byteCharacters.charCodeAt(offset));
          }

          const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });

          formData.append(`design_maker[original_imgs][]`, blob, `original_img_${index}.png`);
        });

        const designId = document.getElementById('canvas-data').dataset.designId;
        formData.append('design_maker[id]', designId);

        fetch('/member/design_maker', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
          },
          body: formData
        })
          .then(response => response.json())
          .then(data => {
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            } else {
              console.error('リダイレクトURLが含まれていません。');
            }
          })
          .catch(error => {
            console.error('エラーが発生しました:', error);
          });
      });
  }

  removeGuide() {
    const guidelineIds = ['left', 'right', 'up', 'down', 'center'];
    this.mockup.getObjects().forEach(obj => {
      if (guidelineIds.includes(obj.id)) {
        this.mockup.remove(obj);
      }
    });
  }

  generateThumbnail() {
    return new Promise((resolve, reject) => {
      this.removeGuide();
      const mockupData = this.mockup.toDataURL();
      const drawingData = this.drawing.toDataURL();

      const combinedCanvas = document.createElement('canvas');
      combinedCanvas.width = this.drawing.width;
      combinedCanvas.height = this.drawing.height;
      const combinedCtx = combinedCanvas.getContext('2d');

      // canvasMockup の画像データを結合
      const mockupImage = new Image();
      mockupImage.src = mockupData;
      mockupImage.onload = () => {
        combinedCtx.drawImage(mockupImage, 0, 0);

        // drawingCanvas の画像データを結合
        const drawingImage = new Image();
        drawingImage.src = drawingData;
        drawingImage.onload = () => {
          combinedCtx.drawImage(drawingImage, 0, 0);

          // 結合した画像データを DataURL として取得
          const combinedData = combinedCanvas.toDataURL();


          resolve(combinedData);
        };
      };
    });
  }

  dataURLToBlob(dataURL) {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  }
}
