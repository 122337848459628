import { IText } from 'fabric';

export function addText(drawing, fontSelectTarget) {
  const selectedFont = fontSelectTarget.value;
  const text = new IText("NEW TEXT", {
    left: 480, top: 153,
    fontFamily: selectedFont,
    fill: '#ffffff',
    originX: 'center',
    originY: 'center',
  });
  drawing.add(text);
  drawing.setActiveObject(text);
}

export function changeTextBold(drawing, event) {
  const isChecked = event.target.checked;
  const activeObject = drawing.getActiveObject();

  if (activeObject && activeObject.type === 'i-text') {
    const newWeight = isChecked ? '700' : '400';

    activeObject.set('fontWeight', newWeight);
  }
}

export function changeTextColor(drawing, event) {
  const selectedColor = event.target.getAttribute('data-color');
  const selectedObject = drawing.getActiveObject();
  if (selectedObject && ["i-text", "rect", "polygon", "triangle", "path", "circle"].includes(selectedObject.type)) {
    selectedObject.set({ fill: selectedColor });
  }
}

export function changeFont(selectedObject, fontSelect) {
  if (selectedObject && selectedObject.type === "i-text") {
    const selectedFont = fontSelect.value;
    selectedObject.set({ fontFamily: selectedFont });
  }
}

