import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTargets.forEach(input => {
      input.addEventListener('keydown', this.preventEnter);
    });
  }

  preventEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}