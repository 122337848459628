import { fetchData } from "../utils/fetch_data";

export class casheData {
  static getVersion() {
    return '202503';
  }

  static getProductData(productType) {
    const cachedData = this.getCachedData(productType);
    const cachedOptionData = this.getCachedOptionData();
    const cachedVersion = localStorage.getItem('version');

    if (!cachedData || cachedVersion !== this.getVersion()) {
      return this.fetchAndUpdateCache(productType);
    }

    return { productHash: cachedData.productHash, optionHash: cachedOptionData };
  }

  static getCachedData(productType) {
    const cachedData = localStorage.getItem('priceListData');
    if (!cachedData) return null;

    const parsedData = JSON.parse(cachedData);
    return parsedData[productType] || null;
  }

  static getCachedOptionData() {
    const cachedOptionData = localStorage.getItem('optionPriceData');
    return cachedOptionData ? JSON.parse(cachedOptionData) : null;
  }

  static async fetchAndUpdateCache(productType) {
    const [productJsonText, optionJsonText] = await Promise.all([
      fetchData(`/json/${productType}_price.json`),
      fetchData('/json/option_price.json'),
    ]);

    const productData = JSON.parse(productJsonText);
    const optionData = JSON.parse(optionJsonText);

    this.saveToLocalStorage(productType, { productHash: productData, lastUpdated: productData.lastUpdated });
    this.saveOptionDataToLocalStorage(optionData);
    localStorage.setItem('version', this.getVersion());

    return { productHash: productData, optionHash: optionData };
  }

  static saveToLocalStorage(productType, data) {
    const cachedData = JSON.parse(localStorage.getItem('priceListData')) || {};
    cachedData[productType] = data;
    localStorage.setItem('priceListData', JSON.stringify(cachedData));
  }

  static saveOptionDataToLocalStorage(optionData) {
    localStorage.setItem('optionPriceData', JSON.stringify(optionData));
  }
}
