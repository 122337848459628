import { Controller } from "@hotwired/stimulus"
import { casheData } from "../utils/unit_price_cache";

// Connects to data-controller="price-sheet"
export default class extends Controller {
  static targets = [
    "toggleUnitPrice", "toggleExpress", "togglePackaging", "toggleInsidePrint",
    "valueWidth",
    "streamPriceTable", "streamTooltip"
  ]

  async connect() {
    await this.loadPriceData();
    this.streamPriceTable(false, false, false, false);
  }

  async loadPriceData() {
    const { productHash, optionHash } = await casheData.getProductData(this.data.get("products"));
    this.productHash = productHash;
    this.optionHash = optionHash;
  }

  streamPriceTable(packaging, insidePrint, express, unitSwitch) {
    const priceTableWrap = this.streamPriceTableTarget;
    const checkedWidth = this.valueWidthTarget?.getAttribute('data-width');

    priceTableWrap.innerHTML = '';
    var table = document.createElement('table');
    table.id = 'js_tablePrice';
    table.classList.add('bl_tablePrice');

    const headerRow = document.createElement('tr');
    this.appendTableHeader(headerRow, checkedWidth);
    table.appendChild(headerRow);

    this.populateTable(table, checkedWidth, packaging, insidePrint, express, unitSwitch);
    priceTableWrap.appendChild(table);

    this.eventsWithCheckd(table);
  }

  streamUpdatePriceTable() {
    const unitSwitch = this.toggleUnitPriceTarget.checked;
    const packaging = this.togglePackagingTarget.checked;
    const insidePrint = this.hasToggleInsidePrintTarget ? this.toggleInsidePrintTarget.checked : false;
    const express = this.hasToggleExpressTarget ? this.toggleExpressTarget.checked : false;
    this.streamPriceTable(packaging, insidePrint, express, unitSwitch);
    const event = new CustomEvent('updateEstimate', { bubbles: true });
    this.element.dispatchEvent(event);
  }

  eventsWithCheckd(table) {
    table.querySelectorAll('th:not(:first-child), td:not(:first-child)').forEach((cell) => {
      cell.addEventListener('mouseover', () => this.addHoveredClasses(table, cell.cellIndex));
      cell.addEventListener('mouseout', () => this.removeHoveredClasses(table));
      cell.addEventListener('click', () => this.handleClick(table, cell.cellIndex));
    });
  }

  appendTableHeader(rowElement, checkedWidth) {
    const quantityHeader = this.createTableHeaderCell('数量', ['bl_tablePrice__title', 'hp_cursorAuto']);
    rowElement.appendChild(quantityHeader);

    for (const [key, value] of Object.entries(this.productHash)) {
      let headerText;
      if (key === "30") {
        headerText = "ワイド(〜25mm)";
      } else if (key === "14") {
        headerText = "スリム(12mm/15mm)";
      } else {
        headerText = `${key}mm`;
      }
      const classNames = ['bl_tablePrice__title'];
      const dataWidth = key;

      if (key === checkedWidth) {
        classNames.push('th_checked');
      }

      const headerCell = this.createTableHeaderCell(headerText, classNames, dataWidth);
      rowElement.appendChild(headerCell);
    }
  }

  createTableHeaderCell(textContent, classNames = [], dataWidth = '') {
    const th = document.createElement('th');
    th.textContent = textContent;
    th.className = classNames.join(' ');
    th.dataset.width = dataWidth;
    return th;
  }

  populateTable(table, checkedWidth, packaging, insidePrint, express, unitSwitch) {
    const priceArray = [];
    for (const [, value] of Object.entries(this.productHash)) {
      priceArray.push(Object.entries(value));
    }

    for (let i = 0; i < priceArray[0].length; i++) {
      const tr = document.createElement('tr');

      // Quantity cell
      const quantity = Number(priceArray[0][i][0]);
      const quantityCell = this.createTableCell(`${quantity.toLocaleString()}〜`, ['bl_tablePrice__pcs']);
      quantityCell.setAttribute('data-qty', quantity);
      tr.appendChild(quantityCell);

      // Data cells
      for (let v = 0; v < priceArray.length; v++) {
        const originalUnitPrice = Number(priceArray[v][i][1]);

        // Calculate total price
        let unitPrice = originalUnitPrice;
        let totalPrice = unitPrice * quantity;
        unitPrice += express ? (totalPrice / 100 * 20) / quantity : 0;
        unitPrice += insidePrint ? this.getValueByCondition(this.optionHash['inside_print'], quantity) : 0;
        unitPrice += packaging ? this.getValueByCondition(this.optionHash['packaging'], quantity) : 0;
        totalPrice = unitPrice * quantity;

        // Create cell with total price and additional information
        const dataCell = this.createTableCell(this.formatTotalPrice(totalPrice, unitPrice, quantity, express, unitSwitch), []);
        dataCell.innerHTML = this.formatTotalPrice(totalPrice, unitPrice, quantity, express, unitSwitch);
        if (Object.keys(this.productHash)[v] === checkedWidth) {
          dataCell.classList.add('checked');
        }
        tr.appendChild(dataCell);
      }

      table.appendChild(tr);
    }
  }

  getValueByCondition(data, input) {
    const sortedKeys = Object.keys(data).map(Number).sort((a, b) => a - b);
    return sortedKeys.reduce((acc, key) => (input >= key ? data[key] : acc), data[sortedKeys[0]]);
  }

  formatTotalPrice(totalPrice, unitPrice, quantity, express, unitSwitch) {
    const totalPriceText = `¥${totalPrice.toLocaleString()}`;
    const getPoint = (totalPrice - (totalPrice % 100)) / 100 * 3;

    if (unitSwitch) {
      const pricePerUnit = `@${unitPrice}`;
      const pointPerUnit = `${getPoint.toLocaleString()}pt`;

      return `${totalPriceText}<br><span class="el_textNomal hp_textSmall">${pricePerUnit} / ${pointPerUnit}</span>`;
    } else {
      return totalPriceText;
    }
  }

  handleClick(table, columnIndex) {
    this.removeAllCheckedClasses(table);
    this.addCheckedClasses(table, columnIndex);
  }

  createTableCell(textContent, classNames = []) {
    const td = document.createElement('td');
    td.textContent = textContent;
    td.className = classNames.join(' ');
    return td;
  }
  
  addHoveredClasses(table, columnIndex) {
    const cells = table.querySelectorAll(`th:nth-child(${columnIndex + 1}), td:nth-child(${columnIndex + 1})`);
    cells.forEach(function (cell) {
      if (cell.tagName === 'TH') {
        cell.classList.add('th_hovered');
      } else {
        cell.classList.add('hovered');
      }
    });
  }

  removeHoveredClasses(table) {
    table.querySelectorAll('th, td').forEach(function (cell) {
      if (cell.tagName === 'TH') {
        cell.classList.remove('th_hovered');
      } else {
        cell.classList.remove('hovered');
      }
    });
  }

  removeAllCheckedClasses(table) {
    table.querySelectorAll('th, td').forEach(function (cell) {
      if (cell.tagName === 'TH') {
        cell.classList.remove('th_checked');
      } else {
        cell.classList.remove('checked');
      }
    });
  }

  addCheckedClasses(table, columnIndex) {
    const self = this;
    const cells = table.querySelectorAll(`th:nth-child(${columnIndex + 1}), td:nth-child(${columnIndex + 1})`);
    cells.forEach(function (cell) {
      if (cell.tagName === 'TH') {
        cell.classList.add('th_checked');
        const valueWidth = cell.getAttribute('data-width');
        const dataWidthElement = self.valueWidthTarget;
        dataWidthElement.setAttribute('data-width', valueWidth);
      } else {
        cell.classList.add('checked');
      }
    });
    const event = new CustomEvent('streamTooltip', { bubbles: true });
    this.element.dispatchEvent(event);
  }





}
