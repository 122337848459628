import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { offset: Number };

  connect() {
    this.checkScroll();
    window.addEventListener("scroll", this.checkScroll);
    document.addEventListener("turbo:submit-end", this.scrollToTop)
  }

  disconnect() {
    window.removeEventListener("scroll", this.checkScroll);
    document.removeEventListener("turbo:submit-end", this.scrollToTop)
  }

  checkScroll = () => {
    const scrollTop = window.scrollY;
    const offset = this.offsetValue || 800;

    if (scrollTop > offset) {
      this.element.classList.add("visible");
    } else {
      this.element.classList.remove("visible");
    }
  };

  scrollToTop = (event) => {
    if (event.detail.success) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
